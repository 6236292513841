











































/* tslint:disable:no-console */
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { api } from "@/store/modules/audit";
const auditModul = namespace(api.namespace);

import AProgressBar from "@/components/controls/AProgressBar.vue";
import AAuditOverview from "@/components/widgets/AAuditOverview.vue";
import AAuditItemsListFilter from "@/components/widgets/AAuditItemsListFilter.vue";
import {
  WorkflowActionPayload,
  WorkflowActionIds,
  WorkflowAction,
} from "@auditcloud/shared/lib/types/WorkflowActions";
import { AuditMetadataClient } from "@/types/Audit";
import { ROUTE_NAMES, DIALOG_NAMES, dialogRoute } from "@/routenames";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { AuditProgress } from "@/store/modules/audit/types";

@Component({
  components: {
    AAuditOverview,
    AAuditItemsListFilter,
    AProgressBar,
  },
})
export default class AuditWrapup extends Vue {
  isIntersecting: boolean = false;
  offsetTop: number = 0;

  onScroll(e: any) {
    if (
      (this.$vuetify.breakpoint.mobile && window.pageYOffset > 64) ||
      window.pageYOffset > 70
    ) {
      this.isIntersecting = true;
    } else {
      this.isIntersecting = false;
    }
  }

  get next() {
    return {
      name: "wrapup",
      params: {
        auditId: this.auditId,
      },
    };
  }

  get prev() {
    return {
      name: ROUTE_NAMES.AUDITEXECUTION,
      params: {
        auditId: this.auditId,
      },
    };
  }

  //missingDefault
  @Prop({
    type: String,
  })
  readonly auditId!: string;

  @Prop({
    type: Object,
  })
  readonly audit!: AuditMetadataClient;

  @auditModul.Getter(api.getters.getAuditProgress)
  currentAuditProgress!: AuditProgress;

  openReportingDialog() {
    const params: any = this.$router.currentRoute.params;
    const routeName = this.$route.name ?? "error";
    const auditId = this.auditId;

    this.$router.push({
      name: dialogRoute(routeName, DIALOG_NAMES.REPORTING_DIALOG),
      params: {
        auditId,
      },
    });
  }

  openIntermediateResultDialog() {
    const params: any = this.$router.currentRoute.params;
    const routeName = this.$route.name ?? "error";
    const auditId = this.auditId;

    this.$router.push({
      name: dialogRoute(routeName, DIALOG_NAMES.INTERMEDIATE_RESULT_DIALOG),
      params: {
        auditId,
      },
    });
  }

  @auditModul.Getter(api.getters.getActionsPerView)
  actionsPerView!: (viewname: string) => string[];

  @auditModul.Getter(api.getters.getAuditStatus)
  auditStatus!: string | null;

  @auditModul.Getter(api.getters.getAuditPermissions)
  permissions!: AuditPermissions;

  get writePermission() {
    return this.permissions.write;
  }

  mounted() {
    this.$vuetify.goTo(0);
    const actionMap: { [id: string]: WorkflowAction } = {
      [WorkflowActionIds.PREPARE_REPORT]: {
        id: WorkflowActionIds.PREPARE_REPORT,
        text: `${this.$t("views.audit_wrapup.prep_report_text")}`,
        icon: "notes",
        handler: () => {
          console.log("Report vorbereiten");
          this.openReportingDialog();
        },
      },
      [WorkflowActionIds.SHOW_INTERMEDIATE_RESULT]: {
        id: WorkflowActionIds.SHOW_INTERMEDIATE_RESULT,
        text: this.$t("views.audit.intermediate_result").toString(),
        icon: "notes",
        handler: () => {
          this.openIntermediateResultDialog();
        },
      },
    };

    const actionsList = this.actionsPerView("wrapup")
      .filter(id => id in actionMap)
      .map(id => actionMap[id]);

    const primary = actionsList.length > 0 ? { primary: actionsList[0] } : {};
    const secondary =
      actionsList.length > 1
        ? {
            secondary: actionsList.slice(1),
          }
        : {};
    const actions: WorkflowActionPayload = { ...primary, ...secondary };

    this.$emit("actions", actions);
  }
}
